<template>
  <div class="private-chat">
    <div class="chat-head">
      <div class="head-left">
        <div class="personage-list"
          v-for="(chat, index) in chatList"
          :key="index"
          @click="selectChat(index)"
          :class="{ active: selectedChatIndex === index }"
        >
          <el-image class="personage_picture" :src="chat.personage_picture" fit="fit"></el-image>
          <span class="personage_name">{{ chat.personage_label }}</span>
        </div>
      </div>
      <div class="head-right" @click="openMenu">
        <el-image :src="require(`../../../assets/image/user.png`)" fit="contain"></el-image>
        <span class="user-count">{{ tagsCount }}人</span>
        <el-cascader
          ref="cascader"
          v-model="selectedOptions"
          :options="options"
          @change="handleChange"
          placeholder="请选择"
          class="hidden-cascader"
        ></el-cascader>
      </div>
    </div>
    <div class="chat-main">
      <div class="chat-hint" v-if="isHint">
        <div class="hint-title">
          <span class="title-left">模拟访谈生涯人物</span>
          <span class="title-right">点击右上角的人物图标，选择聊天对象，进行访谈。</span>
        </div>
        <div class="hint-main">
          <div class="main-title">
            您可以尝试输入：
          </div>
          <div class="main-data">
            <div class="data" @click="updatedData('请问你的主要成就？')">
              <i class="el-icon-edit"></i>
              <span>请问你的主要成就？</span>
            </div>
            <div class="data" @click="updatedData('你成功的秘诀是什么？')">
              <i class="el-icon-edit"></i>
              <span>你成功的秘诀是什么？</span>
            </div>
          </div>
        </div>
      </div>
      <div class="chat-messages" v-if="selectedChat">
        <div class="message"
          v-for="message in selectedChat.personage_messages"
          :key="message.personage_dialogue_id"
        >
          <div class="answer-box f-e">
            <div class="answer">
              <span>{{ message.personage_question }}</span>
            </div>
            <i class="el-icon-user-solid icon-user"></i>
          </div>
          <div class="answer-box">
            <el-image class="answer-image" :src="selectedChat.personage_picture"></el-image>
            <div class="answer" style="white-space: pre-wrap;">
              <div v-if="message.personage_answer === '......'">
                <el-image :src="require(`../../../assets/image/loading.gif`)" fit="contain"></el-image>
              </div>
              <span v-else>{{ message.personage_answer }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box-body">
        <el-image class="exit-2" @click="sendExit" :src="require(`../../../assets/image/exit2.png`)" fit="contain"></el-image>
        <div class="box">
          <el-input
            class="input-box"
            @keydown.native="handleKeyDown"
            :autosize="{ minRows: 2, maxRows: 8 }"
            type="textarea"
            placeholder="在此输入您想了解的内容，按Enter发送一个消息，Shift+Enter换行"
            v-model="userMessage"
          ></el-input>
          <el-button class="but" icon="el-icon-search" @click="sendMessage" type="primary"></el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPersonageTags, getPersonageChat, insertPersonageChat, insertUserPersonage } from '../../../request/api'

export default {
  inject: ['reload'],
  data () {
    return {
      chatList: [],
      selectedChatIndex: 0,
      userMessage: '',
      options: [],
      selectedOptions: [],
      isLoading: false,
      tagsCount: 0,
      isHint: false
    }
  },
  computed: {
    selectedChat () {
      return this.chatList[this.selectedChatIndex] || { personage_messages: [] }
    }
  },
  methods: {
    selectChat (index) {
      this.selectedChatIndex = index
      if (this.selectedChat.personage_messages.length < 1) {
        this.isHint = true
      } else {
        this.isHint = false
      }
    },
    sendExit () {
      this.$emit('update-value')
    },
    updatedData (message) {
      if (this.chatList.length < 1) return this.$message.error('点击右上角的人物图标，选择聊天对象，进行访谈。')

      this.userMessage = message
      this.sendMessage()
      this.userMessage = ''
    },
    // 识别多行文本是否提交
    handleKeyDown (event) {
      if (!event.shiftKey && event.keyCode === 13) {
        this.sendMessage()
        this.userMessage = ''
      }
    },
    async sendMessage () {
      if (this.userMessage.trim() === '' ) return
      if (this.isLoading) return
      if (this.chatList.length < 1) return this.$message.error('点击右上角的人物图标，选择聊天对象，进行访谈。')

      this.isLoading = true
      const loading = {
        personage_answer: '......',
        personage_dialogue_id: Math.floor(Math.random() * 10000),
        personage_question: this.userMessage
      }
      this.selectedChat.personage_messages.push(loading)

      try {
        let postData = {
          personage_chat_id: this.selectedChat.personage_chat_id,
          personage_tags_id: this.selectedChat.personage_tags_id,
          question: this.userMessage
        }
        const res = await insertPersonageChat(postData)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        const chatData = res.data.data

        this.isHint = false
        this.userMessage = ''
        this.isLoading = false
        this.selectedChat.personage_messages.pop()
        this.fadeInText(chatData, chatData.personage_answer)
      } finally {
        this.isLoading = false
      }
    },
    fadeInText (chatData, answer) {
      let index = 0
      chatData.personage_answer = ''
      this.selectedChat.personage_messages.push(chatData)

      function fadeInNextCharacter() {
        if (index < answer.length) {
          chatData.personage_answer += answer[index]
          index++
          setTimeout(fadeInNextCharacter, 50)
        }
      }
      fadeInNextCharacter()
    },
    openMenu () {
      this.$refs.cascader.$refs.input.$el.click();
    },
    async handleChange (value) {
      try {
        let postData = {
          personage_tags_id: value.pop()
        }
        const res = await insertUserPersonage(postData)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        this.$message({
          type: 'success',
          message: '修改成功！'
        })
        this.reload()
      } catch (err) {
        this.$message.error(err)
      }
    },
    async init () {
      const res = await getPersonageTags()
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }

      this.tagsCount = res.data.data.tagsCount
      this.options = this.transformData(res.data.data.topLevelTags)
    },
    transformData (data) {
      return data.map(item => ({
        value: item.personage_tags_id,
        label: item.personage_label,
        children: item.children ? this.transformData(item.children) : undefined
      }))
    },
    async getPersonage () {
      const res = await getPersonageChat()
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }

      res.data.data.forEach(ele => {
        const validMessages = ele.personage_messages.filter(msg => msg.personage_dialogue_id !== null)
        ele.personage_messages = validMessages.length > 0 ? validMessages : []
      })
      this.chatList = res.data.data
      if (this.selectedChat.personage_messages.length < 1) {
        this.isHint = true
      } else {
        this.isHint = false
      }
    },
    scrollContentToBottom () {
      this.$nextTick(() => {
        const contentElement = document.querySelector('.chat-messages')
        if (contentElement) {
          contentElement.scrollTop = contentElement.scrollHeight
        }
      })
    }
  },
  updated () {
    this.scrollContentToBottom()
  },
  mounted () {
    this.init()
    this.getPersonage()
  }
}
</script>

<style lang="less" scoped>
.private-chat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .chat-head {
    display: flex;
    min-height: 13%;
    .head-left::-webkit-scrollbar {
      width: 10px; /* 设置滚动条的宽度为10px */
    }
    .head-left::-webkit-scrollbar-track {
      background: #eff9ff; /* 滚动条轨道颜色 */
    }
    .head-left::-webkit-scrollbar-thumb {
      background: #eff9ff; /* 滚动条滑块颜色 */
    }
    .head-left::-webkit-scrollbar-thumb:hover {
      background: #ecf1f5; /* 滚动条滑块鼠标悬停颜色 */
    }
    .head-left {
      flex: 1;
      display: flex;
      overflow-x: auto;
      .personage-list {
        min-width: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .personage_picture {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .personage_name {
          font-size: 14px;
        }
      }
      .active {
        background-color: #f4f7f9;
        border-bottom: 2px solid #2a6ee9;
      }
    }
    .head-right {
      width: 8%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      .user-count {
        position: absolute;
        color: #fff;
        font-size: 14px;
        top: 56%;
      }
      &:hover {
        background-color: #f4f7f9;
      }
      .hidden-cascader {
        opacity: 0;
        position: absolute;
        pointer-events: none;
        // right: 8%;
        width: 0;
      }
    }
  }
  .chat-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .chat-hint {
      height: 220px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
      .hint-title {
        background-color: #fff;
        height: 30%;
        width: 85%;
        padding: 12px;
        box-sizing: border-box;
        border-radius: 10px;
        display: flex;
        align-items: center;
        .title-left {
          font-weight: bold;
          color: #3b79ea;
          margin-right: 26px;
        }
        .title-right {
          font-size: 14px;
          color: #7a7c7e;
        }
      }
      .hint-main {
        background-color: #fff;
        height: 60%;
        width: 85%;
        padding: 12px;
        box-sizing: border-box;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .main-title {
          font-weight: bold;
          margin-bottom: 4px;
        }
        .main-data {
          display: flex;
          flex: 1;
          font-size: 14px;
          .data {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #d6e7ff;
            cursor: pointer;
            border-radius: 6px;
            margin-right: 10px;
            width: 200px;
            .el-icon-edit {
              margin-right: 4px;
            }
          }
        }
      }
    }
    .chat-messages {
      height: 90%;
      margin-bottom: 10px;
      padding: 0px 6%;
      overflow-y: auto;
      .message {
        margin: 42px 0 20px 0;
        .f-e {
          justify-content: flex-end;
        }
        .answer-box {
          display: flex;
          align-items: center;
          margin-top: 18px;
          .icon-user {
            font-size: 30px;
            color: #2a6ee9;
          }
          .answer-image {
            width: 50px;
            height: auto;
            flex-shrink: 0;
            flex-grow: 0;
            margin-right: 4px;
          }
          .answer {
            background-color: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            padding: 10px;
            color: #555;
          }
        }
      }
    }
    .box-body {
      .exit-2 {
        width: 70px;
        cursor: pointer;
        padding: 1% 3%;
      }
      .box {
        display: flex;
        padding: 0px 6%;
        .input-box {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
</style>